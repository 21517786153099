<template>
  <div class="eta-view container-base section-container-padding px-0">
    <div class="header-container vertical-align w-100">
      <div class="m-auto eta-header-container">
        <h1 class="txt-white fz-27 fw-500">{{ this.courses['orientation'].titleLine1 }} <br> {{ this.courses['orientation'].titleLine2 }} </h1>
        <h2 class="txt-white fz-16 fw-400 my-4">{{ this.courses['orientation'].subtitleLine1 }} <br> {{ this.courses['orientation'].subtitleLine2 }}</h2>
        <b-input-group class="qa-input-group eta-input-group mx-auto mb-3">
          <b-form-input class="li-utility-set-detail-description openSans-100 fz-17 eta-search-input" type="search"
                          :placeholder="$t('eta.searchBar')"
                          v-model="textFilter"
                          @keyup.enter="searchAsk">
          </b-form-input>
          <b-input-group-append >
            <b-button class="eta-button" @click="searchAsk('')" >
                {{ $t('eta.searchButton') }}
            </b-button>
          </b-input-group-append>
          </b-input-group>
          <div class="m-auto" >
            <b-img id="popover-1-bottom" class="mr-2 icon-tooltip-eta" :src="'/assets/images/info-circle-solid.svg'"></b-img>
            <span class="txt-white fz-15 fw-400 my-4">{{ $t('eta.howToSearch') }}</span>
            <b-popover ref="popover" class="wide-popover" target="popover-1-bottom" triggers="hover" placement="auto">
              <div>
                <span>{{ $t('eta.popoverSearch') }}</span>
              </div>
            </b-popover>
          </div>
      </div>
    </div>

    <b-row class="margin-rows knowledge-qa-filters-row mx-0">
      <b-col cols="12">
        <course-list class="mt-4" :courses="courses['orientation'].courses"/>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-between my-3 padding-bottom-row">
      <div>
        <img loading="lazy" style="margin: 0px;" src="https://www.searchic.sorcero.com/wp-content/uploads/2020/05/download-1-1-300x212.png" alt="" width="42" height="auto">
        <strong v-b-modal.modal-1 class="cursor-pointer gray-text">Popular Questions</strong>
      </div>
      <strong @click="goMyActivity" class="cursor-pointer gray-text">My Activity</strong>
    </div>

    <b-modal size="xl" id="modal-1" title="Popular Questions"
              :hide-footer="true">
      <div class="col-12 col-md-8 ask-question-block pl-0">
          <b-link class="fw-400 fz-17 ta-left d-block eta-links"
                  v-for="question in popularQuestions" :key="question.id"
                  @click="askFavorite(question.text)">
            {{ question.text }}
          </b-link>
        </div>
    </b-modal>

    <div class="eta-footer py-3">
      <span class="fw-600">Copyright © 2021 <span @click="copyright" class="cursor-pointer eta-link-highlight">electrical training ALLIANCE</span></span>
    </div>
  </div>

</template>

<script>
import CourseList from "@/modules/eta/components/TrainingList";
import TrainingService from "@/modules/eta/services/TrainingService";
import QAService from "@/modules/knowledge/services/QAService";


export default {
  name: "Course",
  components: {CourseList},
  data() {
    return {
      courses: [],
      textFilter: '',
      popularQuestions: []
    }
  },
  methods: {
    getCourses() {
      TrainingService.getCourses().then(response => {
        this.courses = response.data
      })
    },
    searchAsk(text) {
      this.textFilter = this.textFilter || text
      this.textFilter = this.textFilter.trim()
      if (this.textFilter !== "") {
        this.$router.push(`/${this.$store.getters.getInstance}/my-apps/knowledge/ask_results?question=${this.textFilter}`)
      }
    },
    goMyActivity() {
       this.$router.push(`/${this.$store.getters.getInstance}/user-history`)
    },
    copyright() {
      window.location = `https://www.electricaltrainingalliance.org/`
    },
    getFavoriteQuestions() {
      QAService.getFavoriteQuestions(this.$router.currentRoute.params.bot).then(response => {
        this.popularQuestions = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    askFavorite(text) {
      this.searchAsk(text)
    }
  },
  created() {
    this.getCourses()
  },
  mounted() {
    this.getFavoriteQuestions()
  }
}
</script>

<style lang="scss" scoped>

.eta-header-container {
  width: 50%;
}

.eta-input-group {
  width: 75%;
}


@media (min-width:768px) {

  .popover {
    max-width: 400px !important;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {

  .eta-input-group {
    width: 75%;
  }

  .eta-header-container {
    width: 100%;
  }

}

@media (min-width: 320px) and (max-width: 767px) {

  .eta-input-group {
    width: 90%;
  }

  .eta-header-container {
    width: 100%;
  }

  .eta-search-input {
    border-radius: 0.25rem !important;
    width: 100%;
  }

  .input-group-append {
    width: 100%;
    margin-left: 0px;
  }

  .eta-button {
    width: 100% !important;
    border-radius: 0.25rem !important;
    margin-top: 10px !important;
  }
}

::v-deep .popover-body {
  color: #a1a1a1 !important;
}

.eta-links {
    text-decoration-line: none;
    font-size: 16px;
    color: black;
    font-weight: 400;
    margin-bottom: 1em;
}


::v-deep .modal-header {
  border-bottom: unset;
}

::v-deep .modal-title {
  font-weight: 600 !important;
  font-size: 32px;
  color: #000;
}

.icon-tooltip-eta {
  width: 20px;
  filter: invert(1)
}

.header-container {
  background: url("../../../../public/assets/images/header-bg.jpg") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
  top: 0;
}

.eta-button {
  background-color: #09609f;
  border: unset;
  margin-left:5px;
}

.eta-button:active {
  background-color: #09609f;
}

.eta-button:visited, .eta-button:hover, .eta-button:focus {
  background-color: #1d8755 !important;
  box-shadow: unset !important;
}

.padding-bottom-row {
  padding: 0px 30px;
}

.gray-text {
  color: #4b4b4b;
}

.eta-footer {
  background-color: #3a3a3a;
  margin-top: 75px;

  span {
    color: #CE7B01;
  }
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

span.eta-link-highlight:hover {
  color: white;
  text-decoration: underline;
}

.eta-search-input {
    font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
}

</style>