<template>
  <div>
    <span class="fw-600 fz-22 txt-black d-block mb-2">Search specific courses and their modules</span>
    <b-row class="mx-0" cols="1" cols-sm="2" cols-md="4" cols-lg="5">
      <b-col class="my-2 " v-for="course in courses" :key="course['course_name']">
        <b-card class="mb-2 eta-course cursor-pointer px-0 mx-auto" @click="goTo(course['course_name'])">
          <b-img :src="'/assets/images/'+course.image" alt="" class="eta-course-image"> </b-img>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'

export default {
name: "CourseList",
  props: {
    courses: Array
  },
  methods: {
    goTo(courseName) {
      this.$router.push(`/${store.getters.getInstance}/training/${courseName}`)
    }
  }
}
</script>

<style lang="scss" scoped>

.mb-4 {
  margin-bottom: 4rem;
}

.eta-course {
max-height: 340px;
margin-bottom: 15px;
}

.eta-course-image {
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
}

div.card-body {
  padding: 4px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}
</style>